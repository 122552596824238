import React from 'react'

export const LC130 = ({ ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="1312"
    height="716"
    viewBox="0 0 1312 716"
    {...props}
  >
    <defs>
      <pattern
        id="pattern"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 206 187"
      >
        <image
          width="206"
          height="187"
          href="../lc-130/PA01.121.13E.3111_3.png"
        />
      </pattern>
      <pattern
        id="pattern-2"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 110 147"
      >
        <image
          width="110"
          height="147"
          href="../lc-130/PA01.121.13E.3111_15.png"
        />
      </pattern>
      <pattern
        id="pattern-3"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 37 25"
      >
        <image
          width="37"
          height="25"
          href="../lc-130/PA01.121.13E.3111_12.png"
        />
      </pattern>
      <pattern
        id="pattern-4"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 27 20"
      >
        <image
          width="27"
          height="20"
          href="../lc-130/PA01.121.13E.3111_9.png"
        />
      </pattern>
      <pattern
        id="pattern-5"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 26 30"
      >
        <image
          width="26"
          height="30"
          href="../lc-130/PA01.121.13E.3111_7.png"
        />
      </pattern>
      <pattern
        id="pattern-6"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 20 23"
      >
        <image
          width="20"
          height="23"
          href="../lc-130/PA01.121.13E.3111_6.png"
        />
      </pattern>
      <pattern
        id="pattern-7"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 38 26"
      >
        <image
          width="38"
          height="26"
          href="../lc-130/PA01.121.13E.3111_5.png"
        />
      </pattern>
      <pattern
        id="pattern-8"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 20 22"
      >
        <image
          width="20"
          height="22"
          href="../lc-130/PA01.121.13E.3111_4.png"
        />
      </pattern>
      <pattern
        id="pattern-9"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 40 48"
      >
        <image
          width="40"
          height="48"
          href="../lc-130/PA01.121.13E.3111_11.png"
        />
      </pattern>
      <pattern
        id="pattern-10"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 125 145"
      >
        <image
          width="125"
          height="145"
          href="../lc-130/PA01.121.13E.3111_18.png"
        />
      </pattern>
      <pattern
        id="pattern-11"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 197 230"
      >
        <image
          width="197"
          height="230"
          href="../lc-130/PA01.121.13E.3111_17.png"
        />
      </pattern>
      <pattern
        id="pattern-12"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 257 263"
      >
        <image
          width="257"
          height="263"
          href="../lc-130/PA01.121.13E.3111_13.png"
        />
      </pattern>
    </defs>
    <g
      className="vista-g"
      id="Group_89"
      data-name="Group 89"
      transform="translate(-309 -221)"
    >
      <rect
        id="PA01.121.13E.3111_3"
        width="286"
        height="260"
        transform="translate(579 221)"
        fill="url(#pattern)"
      />
      <image
        id="PA01.121.13E.3111_2"
        width="228"
        height="282"
        transform="translate(309 232)"
        href="../lc-130/PA01.121.13E.3111_2.png"
        data-name="Cremalheira Poliamida 130-150"
        data-url="https://shopirbal.com/pt/produto/cremalheira-poliamida-130-150/"
      />
      <image
        id="PA01.121.13E.3111_1"
        width="190"
        height="240"
        transform="translate(549 626)"
        href="../lc-130/PA01.121.13E.3111_1.png"
        data-url="https://shopirbal.com/pt/produto/volante-laranja/"
        data-name="Volante Laranja"
      />
      <image
        id="PA01.121.13E.3111_10"
        width="400"
        height="536"
        transform="translate(833 401)"
        href="../lc-130/PA01.121.13E.3111_10.png"
      />
      <image
        id="PA01.121.13E.3111_8"
        width="54"
        height="97"
        transform="translate(1179 223)"
        href="../lc-130/PA01.121.13E.3111_8.png"
        data-url="https://shopirbal.com/pt/produto/correia-6pj-636-elastica/"
        data-name="Correia 6PJ 636 Elástica"
      />
      <rect
        id="PA01.121.13E.3111_15"
        width="153"
        height="204"
        transform="translate(1277 241)"
        fill="url(#pattern-2)"
        data-url="https://shopirbal.com/pt/produto/tampa-do-motor-plastica-laranja/"
        data-name="Tampa do Motor Plástica Laranja"
      />
      <image
        id="PA01.121.13E.3111_14"
        width="61"
        height="68"
        transform="translate(1247 413)"
        href="../lc-130/PA01.121.13E.3111_14.png"
      />
      <rect
        id="PA01.121.13E.3111_12"
        width="51"
        height="35"
        transform="translate(1268 626)"
        fill="url(#pattern-3)"
      />
      <rect
        id="PA01.121.13E.3111_9"
        width="38"
        height="28"
        transform="translate(1146 388)"
        fill="url(#pattern-4)"
        data-url="https://shopirbal.com/pt/produto/rolamento-6003-2rs/"
        data-name="Rolamento do Lado do Pinhão RL 6003 2RS"
      />
      <rect
        id="PA01.121.13E.3111_7"
        width="36"
        height="42"
        transform="translate(999 433)"
        fill="url(#pattern-5)"
        data-url="https://shopirbal.com/pt/produto/pinhao-da-cremalheira-b125-cavilha/"
        data-name="Pinhão/Carreto B130 + Cavilha"
      />
      <rect
        id="PA01.121.13E.3111_6"
        width="28"
        height="32"
        transform="translate(889 438)"
        fill="url(#pattern-6)"
        data-url="https://shopirbal.com/pt/produto/interruptor-b1/"
        data-name="Interruptor B1"
      />
      <rect
        id="PA01.121.13E.3111_5"
        width="52"
        height="36"
        transform="translate(799 522)"
        fill="url(#pattern-7)"
        data-url="https://shopirbal.com/pt/produto/rolamento-6006-2rs/"
        data-name="Rolamento do Cubo do Balde 6006 2RS"
      />
      <rect
        id="PA01.121.13E.3111_4"
        width="28"
        height="31"
        transform="translate(720 580)"
        fill="url(#pattern-8)"
      />
      <rect
        id="PA01.121.13E.3111_11"
        width="56"
        height="67"
        transform="translate(1280 799)"
        fill="url(#pattern-9)"
        data-url="https://shopirbal.com/pt/produto/roda-macica-160-com-freio/"
        data-name="Roda Maciça Ø160 Com Freio"
      />
      <rect
        id="PA01.121.13E.3111_18"
        width="46"
        height="53"
        transform="translate(1421 540)"
        fill="url(#pattern-10)"
        data-url="https://shopirbal.com/pt/produto/condensador-700wt/"
        data-name="Condensador 700W"
      />
      <rect
        id="PA01.121.13E.3111_17"
        width="72"
        height="85"
        transform="translate(1549 584)"
        fill="url(#pattern-11)"
        data-url="https://shopirbal.com/pt/produto/ventoinha-para-motor-700w/"
        data-name="Ventoinha para Motor 700W"
      />
      <rect
        id="PA01.121.13E.3111_13"
        width="93"
        height="95"
        transform="translate(1421 661)"
        fill="url(#pattern-12)"
        data-url="https://shopirbal.com/pt/produto/motor-ele-700w/"
        data-name="Motor ELE 700W"
      />
    </g>
  </svg>
)
